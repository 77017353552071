<template>
  <div class="create-page">
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
    <Modal
      :modalShow="isShowApiKeyModal"
      :title="texts.previewPage.modalTitle"
      :messageGeneral="texts.previewPage.modalMessageGeneral"
      :isCloseButton="false"
      :successBtnLabel="texts.previewPage.modalSuccessBtn"
      :classes="['hide-cross']"
      :closeOnBackdrop="false"
      @doFunction="closeModal"
      @toggle-modal="closeAllModals"
    >
      <template #body>
        <div class="d-flex justify-content-end align-items-center">
          <div v-if="isCopied" class="mr-2">Copied</div>
          <i
            role="button"
            class="fa fa-copy zq-card-row--copy-icon mb-2"
            @click="handleCopy(apiKeyData.externalReference)"
          />
        </div>
        <div class="api-key">{{ apiKeyData.externalReference }}</div>
      </template>
    </Modal>
    <Modal
      :modalShow="isShowApiKeyCloseModal"
      :title="'Are you sure you want to close this?'"
      :classes="['close-confirm']"
      :messageGeneral="'Once closed the API key cannot be retrieved.'"
      @doFunction="closeAllModals"
      @toggle-modal="closeConfirmModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import Modal from '@/shared/UI/Modal';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { apiKeys } from '@/config/descriptions/apiKeys.json';
import { apiKeysTexts } from '@/config/pageTexts/apiKeys.json';
import apiKeyFields from '@/generated/ziqni/store/modules/apiKeys/fields';

export default {
  name: 'CreateApiKey',
  components: {
    Modal,
  },
  data() {
    return {
      model: 'apiKeys',
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...apiKeys.create,
      },
      texts: {
        ...apiKeysTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      isShowApiKeyModal: false,
      isShowApiKeyCloseModal: false,
      isCopied: false,
      apiKeyData: {
        externalReference: ''
      },
    };
  },
  computed: {
    ...mapGetters('apiKeys', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('apiKeys', ['handleCreateApiKeys']
    ),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        apiKeyFields,
        apiKeysTexts.createPage,
        apiKeys.create
      );

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.key === 'whiteListIPs') {
          field.placeholder = '123.221.22.1, 333.224.34.1'
        }
        if (field.required) {
          this.requiredFields.push(field.key);
          if (field.type === 'NAMEKEY') {
            this.requiredFields.push('name');
          }
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.translatableFields = apiKeyFields.baseFields.translatableFields;

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    getInvalidFields() {
      let result = [];

      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      return result;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    handleCopy(key) {
      navigator.clipboard.writeText(key);
      this.isCopied = true;
    },
    closeModal() {
      this.isShowApiKeyCloseModal = true;
    },
    closeConfirmModal() {
      this.isShowApiKeyCloseModal = false;
    },
    closeAllModals() {
      this.isShowApiKeyCloseModal = false;
      this.isShowApiKeyModal = false;
      this.goToPreview();
    },
    goToPreview() {
      this.$router.push({
        name: 'PreviewApiKey',
        params: {
          id: this.apiKeyData.id,
        }
      })
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;

        if (this.settingsData.whiteListIPs && this.settingsData.whiteListIPs.length) {
          this.settingsData.whiteListIPs = this.settingsData.whiteListIPs.split(',').map(item => item.trim());
        }

        formData = {...this.settingsData};
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      this.handleCreateApiKeys({createApiKeyRequest: formData})
        .then(data => {
          if (data.length) {
            this.apiKeyData = data[0];
            this.isShowApiKeyModal = true;
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
  .api-key {
    border: solid 1px #cccccc;
    border-radius: 6px;
    padding: 10px;
    max-width: 700px;
    word-break: break-all;
  }
  .close-confirm {
    .modal-dialog-centered {
      max-width: 440px;
    }
    .modal {
      z-index: 1051;
    }
    .modal-backdrop {
      z-index: 1050;
    }
  }
  .hide-cross {
    button.close {
      display: none;
    }
  }
}
</style>
